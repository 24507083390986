export const scrolToItem = item => {
   let element = document.querySelector(
      `#${item.title.replace(/ /g, "").replace(/\//g, "")}`
   );
   let bodyRect = document.body.getBoundingClientRect();
   let headerOffset = 80;
   let elementPosition = element.getBoundingClientRect().top - bodyRect.top;
   let offsetPosition = elementPosition - headerOffset;
   console.log(elementPosition);
   window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
   });
};
export const downloadArticle = href => {
   let host = "/pdf/";
   let path = host + href;
   let a = document.createElement("a");
   a.setAttribute("href", path);
   a.setAttribute("download", path);
   a.setAttribute("target", "_blank");
   a.click();
};
