import { SET_RIGHT_CONTENT, SET_RIGHT_CONTENT_SCHEDULE } from "./actionTypes";

export const setRightSchedule = scheduled_right => ({
   type: SET_RIGHT_CONTENT_SCHEDULE,
   payload: {
      scheduled_right
   }
});
export const setRight = right => ({
   type: SET_RIGHT_CONTENT,
   payload: {
      right
   }
});
