import React from "react";
import Responsive from "../Responsive";

import f1Src from "../images/forensic-p1.png";
import f2Src from "../images/forensic-p2.png";
import f3Src from "../images/forensic-p3.png";
import sindhisSrc from "../images/article-sindhis.png";
import bengaliSrc from "../images/article-bengali.png";
import bengali2Src from "../images/article-bengali2.png";
import marketing1Src from "../images/article-marketing-1.png";
import marketing2Src from "../images/article-marketing-2.png";
import marketing3Src from "../images/article-marketing-3.png";
import marketing4Src from "../images/article-marketing-4.png";
import { Card, Image, Button, Icon } from "semantic-ui-react";
import { downloadArticle } from "../Utils";

const ForensicArticles = [
   {
      _id: 1,
      Header: "Forensic Accounting Part 1",
      Meta: "November 7th, 2017",
      Description: "FORENSIC ACCOUNTING Defined and De-Mystified - Part 1",
      src: f1Src,
      href: "sun-forensic-acctng-part-1-7-nov17.pdf"
   },
   {
      _id: 2,
      Header: "Forensic Accounting Part 2",
      Meta: "November 16th, 2017",
      Description: "FORENSIC ACCOUNTING Defined and De-Mystified - Part 2",
      src: f2Src,
      href: "sun-forensic-acctng-part-2-16-nov17.pdf"
   },
   {
      _id: 3,
      Header: "Insolvency & Restructuring",
      Meta: "December 6th, 2017",
      Description:
         "INSOLVENCY & RESTRUCTURING Defined and De-Mystified - Part 1",
      src: f3Src,
      href: "sun-6-dec2017-insolvency-restructuring.pdf"
   }
];
const CommunityArticles = [
   {
      _id: 4,
      Header: "Sindhis in Malaysia",
      Meta: "May 18th, 2017",
      Description:
         "Demonstration of Determined Resurgence by ... Classical Masters of International Trading and Textile Retail",
      src: sindhisSrc,
      href: "sindhis-in-malaysia.pdf"
   },
   {
      _id: 5,
      Header: "Bengalis in Malaysia",
      Meta: "October 10th, 2016",
      Description: "Malaysia's Paradigm Cache of Educated, Ambitious PMEBTs",
      src: bengaliSrc,
      href: "bengalis-in-malaysia-1-sun-10oct-2016.pdf"
   },
   {
      _id: 6,
      Header: "Bengalis in Malaysia",
      Meta: "October 13th, 2016",
      Description:
         "Bengali Women - Silent Motivators to Parallel Achievers and Empowerment",
      src: bengali2Src,
      href: "bengalis-in-malaysia-2-sun-13-oct-2016.pdf"
   }
];
const MediaArticles = [
   {
      _id: 200,
      Header: "Media Planning",
      Meta: "July 14th, 2017",
      Description: "Media Planning versus Operational Buying",
      src: marketing1Src,
      href: "media-planning-vs-operational-buying-sun-14-jul17.pdf"
   },
   {
      _id: 201,
      Header: "Marketing Evolution",
      Meta: "March 17th, 2016",
      Description: "Marketing Evolution and Re-invention in the Digital Era",
      src: marketing2Src,
      href:
         "marketing-evolution-and-re-invention-in-the-digital-era-sun-17-mar-2016.pdf"
   },
   {
      _id: 202,
      Header: "Print Media",
      Meta: "July 4th, 2013",
      Description:
         "Sustaining Print Media in the Era of Digital Evolution - Part 1",
      src: marketing3Src,
      href: "sustaining-print-media-in-the-era-of-digital-evolution-part-1.pdf"
   },
   {
      _id: 203,
      Header: "Print Media",
      Meta: "July 5th, 2013",
      Description:
         "Sustaining Print Media in the Era of Digital Evolution - Part 2",
      src: marketing4Src,
      href: "sustaining-print-media-in-the-era-of-digital-evolution-part-2.pdf"
   }
];

const Desktop = props => {
   return (
      <div className="Desktop" id="Articles">
         <div className="MainLine">
            <Icon name="caret right" />
            Articles
         </div>
         <div className="Headline">
            Well researched analytical features on a portfolio of subjects,
            enjoying wide readership, earning appreciation and respect from
            multiple sectors
         </div>
         <br />
         <br />
         <br />
         <div className="Cards">
            <h2 className="">Analytical Articles</h2>

            <Card.Group centered>
               {ForensicArticles.map(article => (
                  <Card key={article._id}>
                     <Image src={article.src} wrapped ui={false} />
                     <Card.Content>
                        <Card.Header>{article.Header}</Card.Header>
                        <Card.Meta>{article.Meta}</Card.Meta>
                        <Card.Description>
                           {article.Description}
                        </Card.Description>
                     </Card.Content>
                     <Card.Content
                        extra
                        style={{ textAlign: "center", padding: "1.5em 0" }}>
                        <Button
                           icon
                           labelPosition="left"
                           primary
                           onClick={e => downloadArticle(article.href)}>
                           <Icon name="download" />
                           Download
                        </Button>
                     </Card.Content>
                  </Card>
               ))}
            </Card.Group>
            <h2 className="">Community Feature Articles</h2>
            <Card.Group centered>
               {CommunityArticles.map(article => (
                  <Card key={article._id}>
                     <Image src={article.src} wrapped ui={false} />
                     <Card.Content>
                        <Card.Header>{article.Header}</Card.Header>
                        <Card.Meta>{article.Meta}</Card.Meta>
                        <Card.Description>
                           {article.Description}
                        </Card.Description>
                     </Card.Content>
                     <Card.Content
                        extra
                        style={{ textAlign: "center", padding: "1.5em 0" }}>
                        <Button
                           icon
                           labelPosition="left"
                           primary
                           onClick={e => downloadArticle(article.href)}>
                           <Icon name="download" />
                           Download
                        </Button>
                     </Card.Content>
                  </Card>
               ))}
            </Card.Group>
            <h2 className="">Media & Marketing Articles</h2>
            <Card.Group centered>
               {MediaArticles.map(article => (
                  <Card key={article._id}>
                     <Image src={article.src} wrapped ui={false} />
                     <Card.Content>
                        <Card.Header>{article.Header}</Card.Header>
                        <Card.Meta>{article.Meta}</Card.Meta>
                        <Card.Description>
                           {article.Description}
                        </Card.Description>
                     </Card.Content>
                     <Card.Content
                        extra
                        style={{ textAlign: "center", padding: "1.5em 0" }}>
                        <Button
                           icon
                           labelPosition="left"
                           primary
                           onClick={e => downloadArticle(article.href)}>
                           <Icon name="download" />
                           Download
                        </Button>
                     </Card.Content>
                  </Card>
               ))}
            </Card.Group>
         </div>
      </div>
   );
};

class Component extends React.Component {
   render() {
      return (
         <div className="Articles">
            <Responsive.Desktop>
               <Desktop state={this.state} />
            </Responsive.Desktop>
         </div>
      );
   }
}

export default Component;
