import React from "react";
import { connect } from "react-redux";
import { setRightSchedule } from "../redux/actions";
// import pdsrc from "../images/pd.jpg";

const TB = props => {
   return (
      <strong
         onMouseEnter={e => {
            // let state = {};
            // state[props.name] = false;
            // props.handleChange(e, state);
         }}
         onMouseLeave={e => {
            // let state = {};
            // state[props.name] = true;
            // props.handleChange(e, state);
         }}>
         <span
            className={`TB ${props.class ? props.class : "Black"}`}
            onClick={e => {
               if (props.right === props.id) return;
               props.setRightSchedule(props.id);
            }}>
            {props.children}
         </span>
      </strong>
   );
};

class Component extends React.Component {
   state = { gb: true, ecc: true, ttaa: true };

   render() {
      return (
         <div className="Left">
            <div>
               <span className="Blue">
                  <strong>
                     <TB
                        id="media_experience"
                        class="Blue"
                        icon="globe"
                        {...this.props}>
                        Global Professional
                     </TB>
                  </strong>
               </span>{" "}
               with extraordinary career
               <br />
               <span className="Blue">
                  <strong>
                     {" "}
                     <TB
                        id="the_world"
                        class="Blue"
                        icon="globe"
                        {...this.props}>
                        {" "}
                        across 40 markets in 3 continents
                     </TB>
                  </strong>
               </span>{" "}
            </div>
            <br />
            <div>
               <strong>EMPOWERING</strong> through high-impact media,
               communication and research strategies
            </div>
            <br />
            <div>
               Unrivalled expertise on<strong> CHALLENGER BRANDS</strong>{" "}
               competing against large, established Market Leaders
            </div>
            <br />
            <div>
               <TB id="rare_cache" class="Blue" {...this.props}>
                  {" "}
                  <strong>Rare CACHE of Skill-Sets </strong>
               </TB>
               involving expertise in{" "}
               <TB id="ten_specialist" class="Blue" {...this.props}>
                  <strong>10 Specialist Disciplines</strong>
               </TB>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   let { right, scheduled_right } = state.app;

   return {
      right,
      scheduled_right
   };
};

export default connect(
   mapStateToProps,
   { setRightSchedule }
)(Component);
