import React from "react";
import { Image } from "semantic-ui-react";

import Responsive from "../Responsive";
import { scrolToItem } from "../Utils";

import avatar from "../images/avatar.jpg";

let Items = [
   { title: "Career", onclick: "" },
   { title: "Milestone Cases", onclick: "" },
   { title: "Articles", onclick: "" },
   { title: "Countries/Economic", onclick: "" },
   { title: "Contact", onclick: "" }
];

// 🤵🏻
const Desktop = props => {
   return (
      <div className="Desktop">
         <div className="Right">
            <div className="Name">
               <span role="img" aria-label="Prashun Dutt">
                  <Image src={avatar} size="tiny" verticalAlign="middle" />
               </span>
               &nbsp;Prashun Dutt
            </div>
         </div>
         <div className="Left">
            {Items.map((item, i) => (
               <div className="Item" key={i} onClick={e => scrolToItem(item)}>
                  {item.title}
               </div>
            ))}
         </div>
      </div>
   );
};

class Component extends React.Component {
   render() {
      return (
         <div className="Topbar">
            <Responsive.Desktop>
               <Desktop />
            </Responsive.Desktop>
         </div>
      );
   }
}

export default Component;
