import React from "react";
import Responsive from "../Responsive";
import Left from "./Left";
import Right from "./Right";

const Desktop = props => {
   return (
      <div className="Desktop" id="Career">
         <Left {...props} />
         <Right {...props} />
      </div>
   );
};

class Component extends React.Component {
   state = { foo: "bar" };
   TDButtonClicked() {
      const self = this;
      return type => {
         self.state[type] = self.state[type] ? false : true;
      };
   }
   render() {
      return (
         <div className="Career">
            <Responsive.Desktop>
               <Desktop
                  TDButtonClicked={this.TDButtonClicked()}
                  state={this.state}
               />
            </Responsive.Desktop>
         </div>
      );
   }
}

export default Component;
