import React from "react";
import Responsive from "../Responsive";

import singaporeSrc from "../images/article-singapore.png";
import pakistanSrc from "../images/article-pakistan.png";
import germanySrc from "../images/article-germany.png";
import kazakstanSrc from "../images/article-kazakstan.png";
import kazakstan2Src from "../images/article-kazakstan-2.png";
import { Card, Image, Button, Icon, List } from "semantic-ui-react";
import { downloadArticle } from "../Utils";

const Articles = [
   {
      _id: 1,
      Header: "Singapore",
      Meta: "August 29th, 2013",
      Description: "How and Why It Has Become a Global Business Epicentre",
      src: singaporeSrc,
      href: "brand-singapore-wider-angle-29-aug.pdf"
   },
   {
      _id: 2,
      Header: "Pakistan",
      Meta: "March 23rd, 2015",
      Description: "Rejuvenating & Repositioning Pakistan",
      src: pakistanSrc,
      href: "prashun-dutt-pakistan-sun-23-mar-2015.pdf"
   },
   {
      _id: 5,
      Header: "Germany & Poland",
      Meta: "June 23rd, 2016",
      Description:
         "From Enmity to Friendship in Rejuvenated Europe: 25th Anniversary of Polish German Treaty",
      src: germanySrc,
      href: "germany-poland-alliance-enmity-to-friendship-23-jun-2016.pdf"
   },
   {
      _id: 6,
      Header: "Kazakhstan",
      Meta: "December 7th, 2015",
      Description: "Pursuing a Dynamic Vision",
      src: kazakstanSrc,
      href: "kazakhstan-pursuing-a-dynamic-vision-sun-7-dec2015.pdf"
   },
   {
      _id: 7,
      Header: "Kazakhstan",
      Meta: "December 5th, 2016",
      Description: "Consolidating Relationships: Multi-Vector Foreign Policy",
      src: kazakstan2Src,
      href:
         "kazakhstan-consolidating-relationships-through-constructve-engagement-sun-5-dec-2016.pdf"
   }
];

const Top = props => {
   return (
      <div>
         <div className="MainLine">
            <Icon name="caret right" />
            Countries/Economic
         </div>
         <div className="Headline">
            Countries, both industrialized and developing nations, need to
            communicate directly and through their embassies. They are facing
            peculiar competitive situations. Particularly…
         </div>
         <div className="Container">
            <div className="Row">
               <div className="Col">
                  <div>
                     <div className="Tagline">
                        a) Advanced/Industrialized Nations:
                     </div>
                     <div className="Text">
                        Should detail their policies and willingness to offer
                        infrastructure and industry creation support services to
                        developing nations who reflect stable government and
                        determination to accelerate progress;
                     </div>
                  </div>
               </div>
               <div className="Col">
                  <div>
                     <div className="Tagline">b) Developing Nations:</div>
                     <div className="Text">Should showcase</div>
                     <div className="List">
                        <List>
                           <List.Item>
                              <List.Icon name="arrow right" />
                              <List.Content>
                                 The stability in their political, social and
                                 economic environments;{" "}
                              </List.Content>
                           </List.Item>
                           <List.Item>
                              <List.Icon name="arrow right" />
                              <List.Content>
                                 Demonstrating why Advanced Nations should
                                 PRIORITIZE their country for investing
                                 Industry, Infrastructure, Education, Tourism,
                                 and for
                              </List.Content>
                           </List.Item>
                           <List.Item>
                              <List.Icon name="arrow right" />
                              <List.Content>
                                 Socio-Economic efforts, in preference to other
                                 developing counties;
                              </List.Content>
                           </List.Item>
                        </List>
                     </div>
                  </div>
               </div>
            </div>
            <div className="Row">
               <div className="Col">
                  <div>
                     <div className="Tagline">
                        c) Both Advanced and Developing Nations:
                     </div>
                     <div className="Text">
                        Should detail how their Trade and Policy Agreements are
                        delivering visible results and mutually beneficial
                        progress;
                     </div>
                  </div>
               </div>
               <div className="Col">
                  <div>
                     <div className="Tagline">
                        d) Ambitious Developing Nations with Unique Policies:
                     </div>
                     <div className="Text">
                        Should demonstrate how/why their unique policies are
                        empowering results.
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

const Desktop = props => {
   return (
      <div className="Desktop">
         <Top />
         <br />
         <br />
         <br />
         <div className="Cards">
            <Card.Group centered>
               {Articles.map(article => (
                  <Card key={article._id}>
                     <Image src={article.src} wrapped ui={false} />
                     <Card.Content>
                        <Card.Header>{article.Header}</Card.Header>
                        <Card.Meta>{article.Meta}</Card.Meta>
                        <Card.Description>
                           {article.Description}
                        </Card.Description>
                     </Card.Content>
                     <Card.Content
                        extra
                        style={{ textAlign: "center", padding: "1.5em 0" }}>
                        <Button
                           icon
                           labelPosition="left"
                           primary
                           onClick={e => downloadArticle(article.href)}>
                           <Icon name="download" />
                           Download
                        </Button>
                     </Card.Content>
                  </Card>
               ))}
            </Card.Group>
         </div>
      </div>
   );
};

class Component extends React.Component {
   render() {
      return (
         <div className="Countries" id="CountriesEconomic">
            <Responsive.Desktop>
               <Desktop state={this.state} />
            </Responsive.Desktop>
         </div>
      );
   }
}

export default Component;
