import React from "react";
import Responsive from "../Responsive";

import { Icon } from "semantic-ui-react";

const Desktop = props => {
   return (
      <div className="Desktop">
         <div className="MainLine" style={{ padding: "5%" }}>
            <div className="Right">
               <Icon name="caret right" />
               Contact
            </div>
         </div>
         <div className="Social">
            <div className="Icon">
               <a href="https://www.facebook.com/prashun.dutt" target="_blank">
                  <Icon name="facebook official" />
                  Facebook
               </a>
            </div>
            <div className="Icon">
               <a
                  href="https://www.linkedin.com/in/prashun-dutt-15a7695"
                  target="_blank">
                  <Icon name="linkedin" />
                  LinkedIn
               </a>
            </div>
            <div className="Icon">
               <a href="mailto:prashundutt@gmail.com" target="_blank">
                  <Icon name="mail" />
                  Email
               </a>
            </div>
         </div>
         <div className="Footer">© 2020 Prashun Dutt. All Rights Reserved.</div>
      </div>
   );
};

class Component extends React.Component {
   render() {
      return (
         <div className="Contact" id="Contact">
            <Responsive.Desktop>
               <Desktop state={this.state} />
            </Responsive.Desktop>
         </div>
      );
   }
}

export default Component;
