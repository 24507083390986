import React from "react";
import { Icon, Image, Button } from "semantic-ui-react";
import Responsive from "../Responsive";
import { scrolToItem } from "../Utils";
import forbes from "../images/forbes-campaign.png";

const Desktop = props => {
   return (
      <div className="Desktop">
         <div className="MainLine">
            <div className="Right">
               <Icon name="caret right" />
               Milestone Cases
               <Icon name="caret right" />
               Magazines
            </div>
            {/* <div className="Left TD">
               Next: <Icon name="caret right" />
               Global TV Channels
            </div> */}
         </div>
         <div className="SubHeader">
            {/* Relaunching <span className="Hero">Forbes</span> International */}
            <strong>
               Forbes-Engineered daring, dynamic Marketing and Positioning
               strategies, usurping Thought Leadership
            </strong>
         </div>
         <div className="Body">
            <div className="Left">
               <Image size="big" src={forbes} rounded bordered />
            </div>
            <div className="Right">
               <div className="Tagline">
                  Following an underwhelming initial launch by the US office, I
                  came on-board and engineered daring, high impact Global
                  Positioning and Marketing Strategies for Forbes (international
                  edition).
                  <ul>
                     <li>
                        Usurping Thought Leadership, directly engaging market
                        leader The Economist
                     </li>
                     <li>
                        Achieving much higher readership than WSJ-Europe, IHT,
                        Fortune and Newsweek
                     </li>
                     <li>in Forbes debut in the 2000 EBRS survey</li>
                  </ul>
               </div>
               {/* <div className="Action">
                  <Button
                     icon
                     labelPosition="left"
                     size="large"
                     color="red"
                     onClick={e => scrolToItem({ title: "Contact" })}>
                     <Icon name="mail" />
                     Contact me to Learn More
                  </Button>
               </div> */}
            </div>
         </div>
      </div>
   );
};

class Component extends React.Component {
   render() {
      return (
         <div className="Milestone" id="MilestoneCases">
            <Responsive.Desktop>
               <Desktop state={this.state} />
            </Responsive.Desktop>
         </div>
      );
   }
}

export default Component;
