import React from "react";
import { connect } from "react-redux";
import { Transition, Image } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";

import forbes from "../images/forbes.png";
import mtv from "../images/mtv-logo.png";
import economist from "../images/the-economist-logo.png";
import cnbc from "../images/cnbc-logo.png";
import nickelodeon from "../images/nickelodeon-logo.png";
import thesun from "../images/the-sun-logo.png";
import mccann from "../images/mccann-logo.png";
import ogilvy from "../images/ogilvy-logo.png";
import jwt from "../images/jwt-logo.png";
import world from "../images/world.png";
import { setRight } from "../redux/actions";

const animation = "zoom",
   duration = 500;

const handleTransition = props => {
   if (props.right !== props.scheduled_right)
      props.setRight(props.scheduled_right);
};

const MediaExperience = props => (
   <Transition
      onComplete={e => handleTransition(props)}
      visible={props.media_experience_is_visible}
      animation={animation}
      duration={duration}>
      <div>
         <div className="Container">
            <div className="Logos" style={{ textAlign: "center" }}>
               <Image.Group size="tiny">
                  <Image src={cnbc} centered />
                  <Image src={forbes} centered />
                  <Image src={economist} centered />
               </Image.Group>
            </div>
            <div className="Logos" style={{ textAlign: "center" }}>
               <Image.Group size="tiny">
                  <Image src={nickelodeon} centered />
                  <Image src={thesun} centered />
                  <Image src={mtv} centered />
               </Image.Group>
            </div>
            <div className="Logos" style={{ textAlign: "center" }}>
               <Image.Group size="tiny">
                  <Image src={ogilvy} centered />
                  <Image src={mccann} centered />
                  <Image src={jwt} centered />
               </Image.Group>
            </div>
            <div className="Text" style={{ marginTop: "2em" }}>
               <div className="Description">
                  Executed Chief Marketing Officer, Vice President, Marketing
                  and Research Director Roles with global and regional media
                  channels. Earlier, Media Planner rising to Regional Media
                  Planning Director in top-tier global Ad-Agencies
               </div>
            </div>
         </div>
      </div>
   </Transition>
);

const TheWorld = props => (
   <Transition
      onComplete={e => handleTransition(props)}
      visible={props.the_world_is_visible}
      animation={animation}
      duration={duration}>
      <div>
         <div className="Container">
            <div className="Logos" style={{ textAlign: "center" }}>
               <Image size="big" src={world} />
            </div>
            <div className="Text" style={{ marginTop: "2em" }}>
               <div className="Header">Global Footprint</div>
               <div className="Description">
                  Extensive experience across Asia-Pacific, UK, Europe, Middle
                  East, USA and Canada, with Singapore as current
                  operational-base
               </div>
            </div>
         </div>
      </div>
   </Transition>
);
const RareCache = props => (
   <Transition
      onComplete={e => handleTransition(props)}
      visible={props.rare_cache_is_visible}
      animation={animation}
      duration={duration}>
      <div className="Container">
         <div className="Row">
            <ReactMarkdown
               source={`1. Rare **cache of interlinked-skills** in **marketing, media & communications planning, strategic/account planning, integrated communications, media** and **marketing research**, besides writing opinion leading articles.`}
            />
         </div>
         <div className="Row">
            <ReactMarkdown
               escapeHtml={false}
               source={`2. Simultaneously skilled in <br />&nbsp;&nbsp;&nbsp;a. Marketing and Economic Data Analysis <br />&nbsp;&nbsp;&nbsp;b. Creative `}
            />
         </div>
         <div className="Row">
            <ReactMarkdown
               source={`3. Proven expertise **in driving successful marketing strategies for Challenger Brands** and **disrupting the arrogance of complacent market leaders**.`}
            />
         </div>
         <div className="Row">
            <ReactMarkdown
               source={`4. Near unrivalled expertise in Media Marketing and Communication Planning strategies, techniques, executions.`}
            />
         </div>
         <div className="Row">
            <ReactMarkdown
               escapeHtml={false}
               source={`5. Authoritative expertise in <br />
               &nbsp;&nbsp;&nbsp;a. Media Research development/usage, <br />
               &nbsp;&nbsp;&nbsp;b. Consumer Behaviour and Audience Behavioural analysis. <br />
               &nbsp;&nbsp;&nbsp;c.  Using/interpreting complex data towards generating winning marketing/communication strategies; <br />
               &nbsp;&nbsp;&nbsp;d. Co-relating Advertising, Media, Communication, PR inputs with <br />
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. Brand performance/Market Share; <br />
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. Company perceived image and Stock Market performance; <br />
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. Managing Relationship with Investors, Shareholders;`}
            />
         </div>
      </div>
   </Transition>
);
const TenSpecialist = props => (
   <Transition
      onComplete={e => handleTransition(props)}
      visible={props.ten_specialist_is_visible}
      animation={animation}
      duration={duration}>
      <div>
         <div className="Container">
            <div className="Row">
               1. <strong>Advertising</strong>, working in over{" "}
               <strong>38 categories</strong> and <strong>90+ brands</strong>;
            </div>
            <div className="Row">
               2. Product/competitive <strong> sales & market analysis</strong>;
            </div>
            <div className="Row">
               3. <strong>Strategic planning</strong> for both
               <strong> marketing execution & communications</strong>;
            </div>
            <div className="Row">
               4. <strong>Media planning</strong>;
            </div>
            <div className="Row">
               5. <strong>Media, Consumer & Audience Behaviour Research</strong>{" "}
               translated into marketing strategies;
            </div>
            <div className="Row">
               6. Global and regional<strong> media channels management</strong>
               ;
            </div>
            <div className="Row">
               7. <strong>Media marketing</strong>;
            </div>
            <div className="Row">
               8. <strong>Audience retention </strong>
               (readership/viewership/listenership) &{" "}
               <strong>growth strategies</strong>;
            </div>
            <div className="Row">
               9. Designing and executing{" "}
               <strong>integrated communication strategies</strong>;
            </div>
            <div className="Row">
               10. Writing well-researched{" "}
               <strong>features & opinion-leading articles</strong>;
            </div>
         </div>
      </div>
   </Transition>
);

class Component extends React.Component {
   render() {
      const { props } = this;

      return (
         <div className={"Right " + props.right}>
            <div>
               <MediaExperience {...props} />
               <TheWorld {...props} />
               <RareCache {...props} />
               <TenSpecialist {...props} />
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   let {
      right,
      rare_cache_is_visible,
      media_experience_is_visible,
      the_world_is_visible,
      ten_specialist_is_visible,
      scheduled_right
   } = state.app;

   return {
      right,
      scheduled_right,
      media_experience_is_visible,
      rare_cache_is_visible,
      the_world_is_visible,
      ten_specialist_is_visible
   };
};
// export default VisibilityFilters;
export default connect(
   mapStateToProps,
   { setRight }
)(Component);
