import { SET_RIGHT_CONTENT, SET_RIGHT_CONTENT_SCHEDULE } from "../actionTypes";

const initialState = {
   right: "media_experience",
   scheduled_right: "",
   media_experience_is_visible: true,
   rare_cache_is_visible: false,
   the_world_is_visible: false,
   ten_specialist_is_visible: false,
   right_index: 0
};

export default function(state = initialState, action) {
   switch (action.type) {
      case SET_RIGHT_CONTENT: {
         let { right } = action.payload;
         let transition = `${right}_is_visible`;
         let s = {
            ...state,
            right
         };
         for (let key in s) {
            if (key.includes("_is_visible") && typeof s[key] === "boolean") {
               s[key] = false;
            }
         }
         s[transition] = true;
         console.log(s);
         return s;
      }
      case SET_RIGHT_CONTENT_SCHEDULE: {
         let { scheduled_right } = action.payload;
         let s = {
            ...state,
            scheduled_right
         };
         for (let key in s) {
            if (key.includes("_is_visible") && typeof s[key] === "boolean") {
               s[key] = false;
            }
         }
         return s;
      }
      default:
         return state;
   }
}
