import React from "react";

import "./css/App.css";
import Topbar from "./Topbar";
import Career from "./Career";
import Milestone from "./Milestone";
import Articles from "./Articles";
import Countries from "./Countries";
import Contact from "./Contact";

function App() {
   return (
      <div className="App">
         <Topbar />
         <Career />
         <Milestone />
         <Articles />
         <Countries />
         <Contact />
      </div>
   );
}

export default App;
